.app-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    max-height: 6em;
    min-height: 3.5em;
    position: fixed;
    z-index: 2;
}

#opacityOn {
    opacity: 0.6;
}

#opacityOff {
    opacity: 1;
}

.app-header span {
    font-size: 1em;
    color: #FFCC03;
    font-weight: 700;
    padding: .5em;
}

.app-header span a {
    text-decoration: none;
    color: #FFCC03;
}


.app-header span img {
    width: 70%;
    height: auto;
    opacity: 1;
}

#burger {
    width: 30px;
    z-index: 6;
}

.menu {
    font-family: 'Englebert';
    margin-top: 6em;
    width: 100vw;
    height: 90vh;
    background: #FFCC03;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 4;
}

.menuItem {
    color: #0170b7;
    font-family: 'englebert';
    font-size: 3em;
}

.menuOpen {
    display: block;
}

.menuClosed {
    display: none;
}

#burgerButton {
    background: none;
    border: none;
}