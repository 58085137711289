@font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dosis/v16/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml2xME.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
form {
    background-color: #F6F6F6;
    border: 2px solid blue;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 40vw;
    margin-right: 40vw;
}
input {
    width: 50%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item {
    margin: 1vh;
    background-color: #ffcc03;
    font-family: 'Dosis';
}